@media screen and (max-width : 1024px) {
    .nav_lg{
        display: none;
    }
}
.logo{
    padding: 22px 15px;
}
.nav_lg{
    background-color: #1d1729;
    color: white;
    font-size: 1.1rem;
    user-select: none;
}


nav .user_info{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    padding-right: 10vw;
}
nav .user_info svg{
    font-size: 2.2rem;
    padding: 5px;
    transition: 0.4s ease;
}

nav .user_info section{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}
.nav_lg .navbar_lower .logo{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    position: relative;
}
.nav_lg .navbar_lower .logo .logo_img{
    height: 40px;
    scale: 2.5;
    aspect-ratio: 1/1;
    margin-right: 40px;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets//smurf_elite_logo.png');
}
.nav_lg .nav_links a{
    margin: 0.3% 1% ;
    text-align: center;
    position: relative;
}
.nav_lg .nav_links a::before{
    position: absolute;
    content: "";
    width: 0%;
    height: 1px;
    background-color: aliceblue;
    bottom: -1px;
    transition: 0.4s ease;
}
.nav_lg .nav_links span:hover a::before{
    width: 100%;
}

nav .user_info > span:nth-child(2){
    position: relative;
}
nav .user_info > span:nth-child(2) > div{
    position: absolute;
    background-color: rgb(42, 149, 221) ;
    aspect-ratio: 1/1;
    border-radius: 50%;
    padding: 2px 5px ;
    left: 60%;
    top: -30%;
}
nav .user_info > span:nth-child(1){
    position: relative;
    width: max-content;
}
nav .user_info > span:nth-child(1):hover svg{
    transform: rotateZ(180deg);
}
nav .user_info > span:nth-child(1) .logout{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    background-color: var(--primary-color);
    padding: 5px 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}
nav .user_info > span:nth-child(1):hover .logout{
    opacity: 1;
    z-index: 1;
}
nav .user_info > span:nth-child(1):hover .logout:hover{
    border: 1px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
}
nav .user_info > span:nth-child(1) .my_orders{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    background-color: var(--primary-color);
    padding: 5px 20px;
    left: 50%;
    top:65px;
    transform: translateX(-50%);
    width: max-content;
    color: white;
}
nav .user_info > span:nth-child(1):hover .my_orders{
    opacity: 1;
    z-index: 2000;
}
nav .user_info > span:nth-child(1):hover .my_orders:hover{
    border: 1px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
}