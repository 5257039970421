.product_page_layout{
    max-width: var(--max-layout-width);
    margin: auto;
}
.product_page_layout .product_section{
    display: grid;
    grid-template-columns: 40% 60%;
}
.product_page_layout .product_section .product_image_section{
    width: 100%;
    min-height: 100%;
    position: relative;
    padding: 1vh;
}
.product_page_layout .product_section .product_image_section > .product_image_container {
    aspect-ratio: 1/1;
    width: 85%;
    margin: auto;
    overflow: hidden;
    position: sticky;
    top: 12vh;
}
.product_page_layout .product_section .product_image_section > .product_image_container > .img{
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    background-position: center;
    background-size: cover;
    transition: all 0.5s ease;
}
.product_page_layout .product_section .product_image_section > .product_image_container > .icon_cs2{
    background-image: url('.././../assets/account_images/cs2/cs2_card_image2.png');
}
.product_page_layout .product_section .product_image_section > .product_image_container > .icon_gtav{
    background-image: url('.././../assets/GamePage/gtav.webp');
}
.product_page_layout .product_section .product_image_section > .product_image_container > .icon_valorant{
    background-image: url('.././../assets/GamePage/valorant.webp');
}
.product_page_layout .product_section .product_image_section > .product_image_container > .img:hover{
    scale: 1.075;
}




.product_page_layout .product_section > .product_details{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 2vh;
    align-items: start;

}
.product_page_layout .product_section > .product_details .price{
    color: rgb(238,80,49);
    font-weight: bolder;
    font-size: 1.75rem;
}

.product_page_layout .product_section h2{
    font-family: var(--heading-font-family);
    font-size: 2.1rem;
    line-height: 2rem;
    font-weight: 600;
    color: black;
}
.product_page_layout .product_section button{
    background-color: rgb(238,80,49);
    color: white;
    width: 150px;
    padding: 10px 10px;
    outline: none;
    border: none;
}
.product_page_layout .product_section .button_section > section {
    padding: 15px 5px;
}
.product_page_layout .product_section .button_section > section  span {
    cursor: pointer;
}

.product_page_layout .product_section .button_section svg{
    color: rgb(238,80,49);

}
.product_page_layout .product_details > section{
    width: 100%;
}
.product_page_layout .product_details .specification{
    border: 1px solid #6a6a6a7a;
    margin-top: 20px;
}
.product_page_layout .product_details h3{
    padding: 15px 0px 0 20px;
}
.product_page_layout .product_section .table{
    display: grid;
    grid-template-columns: 35% 65%;
    width: 100%;
    margin-top: 2vh;
    border-top: 1px solid #6a6a6a31;
}
.product_page_layout .product_section .table span{
    padding: 15px 20px;
  }
.product_page_layout .product_section .table span:nth-child(2n){
  color: #6a6a6a8f;
  font-weight: bold;  
  min-height: 4vh;
}
@media screen and (max-width : 1025px) {
    .product_page_layout .product_section{
        display: grid;
        grid-template-columns: 100%;
    }
    .product_page_layout .product_section > .product_details{
        padding: 1vh 2vh;
    }
}