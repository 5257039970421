.gamepage_container{
    display: flex;
    min-height: 90vh;
}
.game_page_wrapper{
    margin-inline: auto;
    width: 100%;
    max-width: var(--max-layout-width);
    display: grid;
    grid-template-columns: 20% 80%;
}
.game_page_wrapper > section{
    padding: 20px;
}
.product_section{
    display: grid;
    grid-template-columns: repeat(4 , calc( (100% / 4) - 15px ));
    column-gap: 20px;
    row-gap: 20px;
    place-items: center;
}
.product_section > .game_card {
    width: 100%;
    max-width: 250px;
    position: relative;
    overflow: hidden;
}
.product_section > .game_card > .img_wrapper{
    overflow: hidden;
}
.product_section > .game_card > .img_wrapper > .img{
    width: 100%;
    aspect-ratio: 3/4;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgba(238, 238, 238, 0.486);
    transition: scale 0.4s ease;
    position: relative;
}
.product_section > .game_card > .img_wrapper > .icon_cs2{
    background-image: url('../../assets/account_images/cs2/cs2_card_image_3x4.png');
}
.product_section > .game_card > .img_wrapper > .icon_gtav{
    background-image: url('../../assets/GamePage/gtav.webp');
}
.product_section > .game_card > .img_wrapper > .icon_valorant{
    background-image: url('../../assets/GamePage/valorant.webp');
}
.product_section > .game_card:hover > .img_wrapper  > .img{
    scale: 1.1;
}
.product_section > .game_card > section{
    text-align: center;
    padding: 5px 10px;
}
.product_section > .game_card .details p {
    color: #6a6a6a;

}
.product_section > .game_card .details > .title{
    font-family: var(--heading-font-family);
    font-weight: bolder;
    font-size: 1.2rem;
}
.product_section > .game_card:hover .details > .title{
    color: var(--primary-color);
}
.product_section > .game_card > .icons{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 2.5%;
    right: -50px;
    transition: all 0.5s ease;
}
.product_section > .game_card:hover > .icons{
    right: 2.5%;
}
.product_section > .game_card > .icons > .icon{
    font-size: 1.2rem;
    color:var(--primary-color);
    background-color: white;
    aspect-ratio: 1/1;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    cursor: pointer;
    transition: 0.3s ease;
}
.product_section > .game_card> .icons > .icon:hover {
    color:white;
    background-color: var(--primary-color);
}














.filter_section > section {
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.233);
}
.filter_section > section > div:first-child {
    cursor: pointer;
} 
.filter_section > section > div{
    display: flex;
    justify-content: space-between;
}
.filter_section > section > div:nth-child(2){
    display: flex;
    padding: 5px 10px;
    transition: all 0.5s ease;
    height: max-content;
}
.filter_section > section > div:nth-child(2)>span{
    cursor: pointer;
    border-radius: 20px;
    padding-inline: 10px;
}
.filter_section > section > section{
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    transition: all 0.5s ease;
    overflow: hidden;

}
.filter_section > section > section >span{
    cursor: pointer;
    width: max-content;
    border-radius: 20px;
    padding-inline: 10px;
}
.filter_section .selected{
    background-color: #6a6a6a;
    color: white;
}
.filter_section .show{
        height: 100px;
    }
    .filter_section .hide{
        height: 0;
        opacity: 0;
    }
@media screen and (max-width : 1100px) {
    .game_page_wrapper{
        display: flex;
        flex-direction: column;
    }
    
}
@media screen and (max-width : 1100px) {
    .product_section{
        grid-template-columns: repeat(3 , calc( (100% / 3) - 15px ));
    }
}
@media screen and (max-width : 580px) {
    .product_section{
        grid-template-columns: repeat(2 , calc( (100% / 2) - 15px ));
    }
}
@media screen and (max-width : 400px) {
    .product_section{
        grid-template-columns: repeat(1 , calc( (100% / 1) - 15px ));
        place-items: center;
    }
}