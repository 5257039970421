.btn_primary_1{
    padding: 12px 40px;
    color: white;
    background-color: transparent;
    font-size: 1.2rem;
    outline: none;
    border: none;
    position: relative;
    z-index: 10;
}
.glass_effect {
    position: absolute;
    background-color: var(--primary-color);
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.glass_effect::before {
    content: "";
    transition: all 0.6s ease;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right,
                    hsla(256, 88%, 72%, 0.686),
                    hsla(256, 88%, 72%, 0.4),
                    hsla(256, 88%, 72%, 0.3),
                    hsla(256, 88%, 72%, 0.2),
                    hsla(256, 88%, 72%, 0.1),
                    transparent,
                    transparent,
                    transparent,
                    transparent
                    );
    top: 0;
    left: 0;
    z-index: -1;
}
.btn_primary_1:hover .glass_effect::before{
    transform: rotateY(180deg);
}