@media screen and (min-width : 1025px) {
    .nav_mobile{
        display: none;
    }
}
.nav_mobile{
    user-select: none;
}
.nav_mobile *{
    transition: 0.8s ease;
}
.navbar_mobile_upper{
    min-height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.nav_mobile .nav_mob_collapse{
    color: black ;
    font-size: 45px;
    margin-right: 30px;
    cursor: pointer;
}
.navbar_mobile_lower{
    overflow: hidden;
    padding: 0vh 1vw;
}
.navbar_mobile_lower .nav_links > div{
    width: 98vw;
    height: 7vh;
    background-color: #f0f0f3;
    border-bottom: 1px solid #b9b9b97e;
    padding: 5%;
    font-size: 0.95rem;
    cursor: pointer;
}
.nav_mobile .logo{
    display: flex;
    justify-content: space-evenly;
    font-size: 1.5rem;
}
.nav_mobile .logo .logo_img{
    height: 40px;
    scale: 2.5;
    aspect-ratio: 1/1;
    margin-right: 40px;
    margin-left: 40px;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets//smurf_elite_logo.png');
}