
.nav_fixed{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--navbar-z-index);
    background-color: rgb(255, 255, 255);
    min-height: 10vh;
    transition: 0.5s ease;
}
@media screen and (max-width : 1024px) {
    .nav_fixed{
        display: none;
    }
}
.nav_fixed > .navbar{
    max-width: 1030px;
    min-width: 1024px;
}
.nav_fixed .nav_links{
    margin-right: auto;
}

.nav_fixed .nav_links a{
    margin: 0.3% 1% ;
    text-align: center;
    position: relative;
}
.nav_fixed .nav_links a::before{
    position: absolute;
    content: "";
    width: 0%;
    height: 1px;
    background-color: rgb(0, 0, 0);
    bottom: -1px;
    transition: 0.4s ease;
}
.nav_fixed .nav_links span:hover a::before{
    width: 100%;
}

.nav_fixed  .queries{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    
}
.nav_fixed  .queries svg{
    font-size: 2.7rem;
    color: var(--primary-color);
    transition: 0.4s ease;
}
.nav_fixed  .queries:hover svg{
    transform: rotateY(180deg);
}
.nav_fixed  .queries section{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}
.nav_fixed .logo{
    display: flex;
    font-size: 1.5rem;
}
.nav_fixed .logo .logo_img{
    height: 40px;
    scale: 2.5;
    aspect-ratio: 1/1;
    margin-right: 40px;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets//smurf_elite_logo.png');
}
.nav_fixed .user_info{
    padding-right: 0vw;
}