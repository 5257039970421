.about_container{
    min-height: 890px;
    max-width: 100vw;
}
.about_wrapper{
    max-width: var(--max-layout-width);
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
}
.about_wrapper section{
    border-radius: 10px;
}
.about_wrapper > section {
    min-height: 700px;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
}
.about_wrapper > section:nth-child(2){
    flex-direction: column;
}
.about_wrapper > section >section {
    min-height: 700px;
}
.about_wrapper .about_left_left{
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
}
.about_left_right > .img{
    height: 100%;
    background-image: url('../../assets/HomePage/About/about_left_right_image.jpg');
    background-position: center;
    background-size: cover;
}
.about_left_left_upper{
    height: 72.5%;
    background-image: url('../../assets/HomePage/About/about_left_left_upper.jpg');
    background-position: center;
    background-size: cover;
}
.about_left_left_lower{
    height: 25%;
    background-color: var(--primary-color);
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 40px;
    background-image: url('../../assets/HomePage/About/about_left_left_lower.jpg');
    background-position: center;
    background-size: cover;
}

.about_left_left_lower .about_experience_text{
    text-transform: uppercase;

}
.about_experience_years{
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    gap: 10px;
}
.about_experience_years > .logo svg{
    font-size: 3.5rem;
    padding: 6px;
    border-radius: 10px;
    transition: all 0.5s ease;
}
.about_left_left_lower:hover svg{
    transform: rotateY(180deg);
}

.about_right{
    color: #6a6a6a;
}

.about_right .welcome_text{
    position: relative;
    height: max-content;
    
    font-size: 1.25rem;
    width: max-content;
}
.about_right .welcome_text::after{
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -3px;
    height: 6px;
    width: 6px;
    margin-left: 36px;
    background: linear-gradient(to left,var(--theme-color3),var(--theme-color1));
    content: "";
    border-radius: 50%;
}

.about_right .welcome_text::before{
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 26px;
    margin-left: 10px;
    background: linear-gradient(to left,var(--theme-color3),var(--theme-color1));
    content: "";
}
.about_right  section{
    border-radius: 0px;
    justify-content: space-between;
}

.about_right_heading{
    font-family: var(--heading-font-family);
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: 600;
    color: black;
}
.about_right_para1{
    padding-top: 20px ;
    padding-bottom: 20px;
}

.about_right_icon_text{
    display: flex;
    gap: 20px;
}
.about_right > .row:nth-child(4){
    margin-bottom: 25px;
    border-bottom: 1px solid #6a6a6a57;
}
.about_right > .row:nth-child(4),.row:nth-child(5){
    margin-top: auto;
    margin-bottom: auto;
}
.about_right_button{
    margin-top: auto;
}

.about_right_icon_text > section:nth-child(2) > span{
    font-family: var(--heading-font-family);
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 600;
    color: black;
}
.about_right_icon_text > section:nth-child(2) > p{
    padding-top: 10px;
    line-height: 1.9rem;

}

.about_right_icon_text .icon_container{
    background-color: #f4f3fb;
    color: var(--primary-color);
    font-size: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 3px solid var(--primary-color);
    padding-inline: 5px;
    width: 90px;
    border-radius: 0 0 45px 45px;
    margin-bottom: 20px;
    transition: all 0.4s ease;
    min-height: 100px;
}
.about_right_icon_text .icon_container:hover{
    background-color: var(--theme-color2);
    color: white;
}

@media screen and (max-width:500px) {
    .about_right_icon_text{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about_left{
        padding: 0;
    }

    .about_left *{
        border-radius: 0 !important;
    }

    .about_wrapper{
        padding: 0;
    }
    .about_right {
        padding: 20px !important;
    }
}
@media screen and (max-width:1024px) {
    
    .about_left{
        padding: 0;
    }
    
    .about_left *{
        border-radius: 0 !important;
    }
    
    .about_wrapper{
        padding: 0;
    }
    .about_right {
        padding: 20px !important;
    }
}
@media screen and (max-width:768px){
    .about_container{
        display: none;
    }
    
}










































.shiny_glass_effect{
    position: relative;
    overflow: hidden;
}

.shiny_glass_effect:before {
    position: absolute;
    top: 0;
    left: -200%;
    display: none;
    content: "";
    width: 50%;
    height: 100%;
    background: -o-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
    background: -webkit-gradient(linear,left top,right top,from(rgba(255,255,255,0)),to(rgba(255,255,255,.3)));
    background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
    transition: all .5s ease
}

@media (prefers-reduced-motion:reduce) {
    .shiny_glass_effect:before {
        transition: none
    }
}

.shiny_glass_effect:hover:before {
    -webkit-animation: shine 1s;
    animation: shine 1s;
    display: block;
}

@-webkit-keyframes shine {
    100% {
        left: 200%
    }
}

@keyframes shine {
    100% {
        left: 200%
    }
}