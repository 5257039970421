

/* //////////////   Counter Strike 2    ////////////////////////////////// */

.cs2_product_image{
    display: grid ;
    padding-top: 20px;
    padding-left: 10px;
    grid-template-columns: repeat(5, calc((90% / 5)));
    column-gap: calc(10% / 4);
    grid-template-rows: repeat(5, calc((90% / 5)));
    transition: all 0.4s ease;
    width: 100%;
}
.cs2_product_image *{
    user-select: none;
}
.cs2_product_image > .medals{
    width: 100%;
    /* aspect-ratio: 1/1; */
    /* position: absolute; */
    background-position: center;
    background-size: cover;
    display: flex;
}

.cs2_product_image > .hours_played{
    position: absolute;
    font-size: 2rem;
    color: rgb(236, 149, 35);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
    z-index: 10;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    bottom: 0%;
    right: 3%;
    text-shadow: 1px 1px 2px white;
    transition: all 0.4s ease;
}
.product_section > .game_card:hover .cs2_product_image > .hours_played{
    right: 7%;
    bottom: 5%;
}
.cs2_product_image > .prime_accounts{
    position: absolute;
    font-size: 2rem;
    color: rgb(236, 149, 35);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
    z-index: 10;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    bottom: 15%;
    right: 3%;
    text-shadow: 1px 1px 2px white;
    transition: all 0.4s ease;
}

.product_section > .game_card:hover .cs2_product_image > .prime_accounts{
    right: 7%;
    bottom: 20%;
}
@media screen and (max-width: 1175px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 1.8rem;
    }
}
@media screen and (max-width: 1100px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 2rem;
    }
}
@media screen and (max-width: 722px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 1.8rem;
    }
}
@media screen and (max-width: 658px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 1.6rem;
    }
}
@media screen and (max-width: 576px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 2rem;
    }
}

@media screen and (max-width: 499px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 400px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 2rem;
    }
}

@media screen and (max-width: 270px) {
    .product_section .cs2_product_image > .prime_accounts{
        font-size: 1.8rem;
    }
}

.cs2_product_image > .medal_2015{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2015.png');
}

.cs2_product_image > .medal_2016{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2016.png');

}

.cs2_product_image > .medal_2017{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2017.png');
}

.cs2_product_image > .medal_2018{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2018.png');

}

.cs2_product_image > .medal_2019{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2019.png');
}

.cs2_product_image > .medal_2020{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2020.png');
}

.cs2_product_image > .medal_2021{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2021.png');
}

.cs2_product_image > .medal_2022{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2022.png');
}

.cs2_product_image > .medal_2023{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2023.png');
}

.cs2_product_image > .medal_2024{
    background-image: url('../../assets/account_images/cs2/service_medals/cs2_service_medal_2024.png');
}
