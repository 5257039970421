.footer2 .text-small {
    font-size: 0.9rem;
  }
  
  .footer2 a {
    
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .footer2 a:hover, a:focus {
    text-decoration: none;
  }
  
  .footer2 .form-control {
    background: #212529;
    border-color: #545454;
  }
  
  .footer2 .form-control:focus {
    background: #212529;
  }
  
 footer {
    background-color: var(--theme-color2);
    color: #6a6a6a ;
    max-width: 100vw;
  }
  li[hover="0.2"]:hover{
    scale: 1.1;
  }
  img[instagram=""]:hover{
    box-shadow: 3px 3px 10px #C13584 , 
    3px -3px 10px #C13584 ,
    -3px -3px 10px #C13584 ,
    -3px 3px 10px #C13584 ;
    border-radius: 4px;
  }
  img[twitter=""]:hover{
    box-shadow: 3px 3px 10px  #1DA1F2 ,
     3px -3px 10px  #1DA1F2 ,
     -3px -3px 10px  #1DA1F2 ,
     -3px 3px 10px  #1DA1F2 ;
     border-radius: 50%;
  }
  img[facebook=""]:hover{
    box-shadow: 3px 3px 10px #1877F2 ,
     3px -3px 10px #1877F2 ,
     -3px -3px 10px #1877F2 ,
     -3px 3px 10px #1877F2 ;
     border-radius: 50%;
  }
  .footer_logo{
    filter: drop-shadow(0px 4px 2px rgba(255, 255, 255, 0.717));
  }