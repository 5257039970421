.contact_wrapper{
    background-color: rgb(29,23,41);
    min-height: 740px;
    color: white;
    margin: 0;
    max-width: 100vw;
    
}
.contact{
    max-width: var(--max-layout-width);
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, calc((100% / 2)));
    place-items: center;
}
@media screen and (max-width:900px) {
    .contact{
        grid-template-columns: repeat(1, calc((100% / 1)));
        padding-top: 100px;
    }
}
.contact_right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.contact_right form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary-color);
    width: 90%;
    max-width: 503px;
    padding: 60px;
    position: relative;
    /* margin-bottom: -200px; */
}
@media screen and (max-width:900px) {
    .contact_right form{
    margin-bottom: 60px;
        
    }
}
.contact_right form input{
    width: 100%;
    outline: none;
    height: 60px;
    padding: 14px 20px;
    margin-bottom: 20px;
}
.contact_right form textarea{
    width: 100%;
    padding: 14px 20px;
    margin-bottom: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
.contact_right form button{
    background-image: linear-gradient(to right,var(--theme-color1-lighter),var(--theme-color1));
    min-height: 60px;
    padding: 20px 50px;
    outline: none;
    border: 1px solid white;
    color: white;
    font-family: var(--heading-font-family);
    width: 60%;
}
.contact_right form button .loading{
    height: 100%;
    max-height: 18px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border-top: 1px solid white;
    border-right: 1px solid white;
    margin: auto;
}
.contact_right form button .loading{
    animation: loading 1s infinite linear;
}
@keyframes loading {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
@media screen and (max-width:400px) {
    .contact_right form{
        padding: 10px;
    }
    .contact_right form input{
        padding: 5% 10%;
    }
}


.contact_left{
    padding: 40px;
}
.contact_left span:first-child{
    color: #bbbaba;
}
.contact_left > h2 {
    font-family: var(--heading-font-family);
    font-weight: bolder;
}
.contact_left > div {
    display: flex;
    margin-top: 30px;
    gap: 20px;
}
.contact_left > div > section{
    max-width: 350px;
    display: flex;
    align-items: center;
}
.contact_left > div > .icon {
    height: 50px;
    min-width: 50px;
    display: grid;
    place-items: center;
    border-radius: 10px;
    background-color: var(--primary-color);
    transition: all 0.5s ease;
    font-size: 1.25rem;
    cursor: pointer;
}
.contact_left > div:hover > .icon{
    background-color: white;
    color: var(--primary-color);
    transform: rotateY(180deg);

}
.contact_left > div  h3{
    font-family: var(--heading-font-family);
    font-weight: bolder;
    font-size: 1rem;
}
.contact_left > div  p{
    color: #bbbaba;
}
