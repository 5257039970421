.card .title{
    margin-bottom: 5vh;
}
.card{
    margin: auto;
    max-width: var(--max-layout-width);
    width: 90%;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 1rem;
    border: transparent;
}
@media(max-width:767px){
    .card{
        margin: 3vh auto;
    }
}
.card .cart{
    background-color: #fff;
    padding: 4vh 5vh;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}
@media(max-width:767px){
    .card .cart{
        padding: 4vh;
        border-bottom-left-radius: unset;
        border-top-right-radius: 1rem;
    }
}
.card .summary{
    background-color: #ddd;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 4vh;
    color: rgb(65, 65, 65);
}
@media(max-width:767px){
    .card .summary{
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
    }
}
.card .summary .col-2{
    padding: 0;
}
.card .summary .col-10
{
    padding: 0;
}.card .row{
    margin: 0;
}
.card .title b{
    font-size: 1.5rem;
}
.card .main{
    margin: 0;
    padding: 2vh 0;
    width: 100%;
}
.card .col-2, .col{
    padding: 0 1vh;
}
.card a{
    padding: 0 1vh;
}
.card .close{
    margin-left: auto;
    font-size: 0.7rem;
    cursor: pointer;
    min-width: 30px;
    aspect-ratio: 1/1;
    display: inline-block;
    text-align: center;
    margin-right: auto;
}
.cart_product_img >section{
    width: 3.5rem;
    height: 3.5rem;
    background-position: center;
    background-size: contain;
    position: relative;
}
.cart_product_img[class*="sold"] > section{
}
.cart_product_img[class*="sold"] .sold_text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 100%;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    z-index: 1;
    aspect-ratio: 1/1;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.cart_product_img > .icon_cs2{
    background-image: url('../../assets/GamePage/csgo.png');
}
.cart_product_img > .icon_valorant{
    background-image: url('../../assets/GamePage/valorant.webp');
}
.cart_product_img > .icon_gtav{
    background-image: url('../../assets/GamePage/gtav.webp');
}
.card .back-to-shop{
    margin-top: 4.5rem;
}
.card h5{
    margin-top: 4vh;
}
.card hr{
    margin-top: 1.25rem;
}
.card form{
    padding: 2vh 0;
}
.card select{
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1.5vh 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: rgb(247, 247, 247);
}
.card input{
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: rgb(247, 247, 247);
}
.card input:focus::-webkit-input-placeholder
{
      color:transparent;
}
.card .btn{
    background-color: #000;
    border-color: #000;
    color: white;
    width: 100%;
    font-size: 0.7rem;
    margin-top: 4vh;
    padding: 1vh;
    border-radius: 0;
}
.card .btn:focus{
    box-shadow: none;
    outline: none;
    box-shadow: none;
    color: white;
    -webkit-box-shadow: none;
    -webkit-user-select: none;
    transition: none; 
}
.card .btn:hover{
    color: white;
}
.card a{
    color: black; 
}
.card a:hover{
    color: black;
    text-decoration: none;
}
 #code{
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.253) , rgba(255, 255, 255, 0.185)), url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
}