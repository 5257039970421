.payment_status_page{
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 2rem;
    color: rgb(93, 93, 93);
    font-weight: 600;
    gap: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--payment-z-index);
}