.login_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: calc(var(--login-z-index) - 1) ;
}
.login_container{
	width: min(350px , 95vw);
	height: 500px;
	background: red;
	overflow: hidden;
	background:  linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    z-index: var(--login-z-index);
    transition: all 0.6s ease;
}
.login_container #chk{
	display: none;
}
.login_container .signup{
	position: relative;
	width:100%;
	height: 100%;
}
.login_container label{
	color: #fff;
	font-size: 2.3em;
	justify-content: center;
	display: flex;
	margin: 60px;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}
.login_container input{
	width: 60%;
	height: 40px;
	background: #e0dede;
	justify-content: center;
	display: flex;
	margin: 20px auto;
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
}
.login_container button{
	width: 60%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: #573b8a;
	font-size: 1em;
	font-weight: bold;
	margin-top: 20px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}
.login_container button:hover{
	background: #6d44b8;
}
.login_container .login{
	height: 460px;
	background: #eee;
	border-radius: 60% / 10%;
	transform: translateY(-180px);
	transition: .8s ease-in-out;
}
.login_container .login label{
	color: #573b8a;
	transform: scale(.6);
}

.login_container #chk:checked ~ .login{
	transform: translateY(-500px);
}
.login_container #chk:checked ~ .login label{
	transform: scale(1);	
}
.login_container #chk:checked ~ .signup label{
	transform: scale(.6);
}
.login_container .external_login{
	background-color: transparent;
	display: flex;
}
.login_container .external_login:hover{
	background-color: transparent;
}
.login_container form button > .loading{
	height: 55%;
	aspect-ratio: 1/1;
	margin: auto;
	border-radius: 50%;
	border-top: 1px solid white;
	border-left: 1px solid white;
}
.login_container form button > .loading{
	animation: loading 1s linear infinite;
}
@keyframes loading{
	0%{
		transform: rotate(0);
	}
	100%{
		transform: rotate(360deg);
	}
}