.service_section1{
    margin: auto;
}
.service_s1_upper{
    background-image: url('../../assets/HomePage/Service/service_section1_background.jpg');
    background-size: cover;
    background-position: center;
    height: 569px;
    position: relative;
    display: grid;
    place-items: center;
}
.service_s1_upper .text_cover{
    max-width: var(--max-layout-width);
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-75%);
}
@media screen and (max-width:472px) {
    .service_s1_upper .text_cover{
        transform: translateY(-50%);
    }
    
}
.service_s1_upper .text_cover h2{
    font-family: var(--font-current-theme1);
    font-weight: bolder;
    max-width: 700px;
    text-wrap: wrap;
    font-size: 2.7rem;
}
@media screen and (max-width:352px) {
    .service_s1_upper .text_cover h2{
        font-size: 2rem;
    }
    .service_s1_upper .text_cover{
        transform: translateY(-75%);
    }
    
}
.service_s1_upper .text_cover span{
    font-size: 1.1rem;
    padding: 20px 0px;
    position: relative;
}
.service_s1_upper .text_cover span::after{
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -3px;
    height: 6px;
    width: 6px;
    margin-left: 36px;
    background: linear-gradient(to left,var(--theme-color3),var(--theme-color1));
    content: "";
    border-radius: 50%;
}

.service_s1_upper .text_cover span::before{
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 26px;
    margin-left: 10px;
    background: linear-gradient(to left,var(--theme-color3),var(--theme-color1));
    content: "";
}
.service_s1_lower{
    max-width: var(--max-layout-width);
    margin: auto;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3 , calc((100% / 3))) ;
    column-gap: 10px;
    row-gap: 20px;
    margin-bottom: -150px;
}

@media screen and (max-width:1000px) {
    .service_s1_lower{
        grid-template-columns: repeat(2 , calc((100% / 2))) ;
    }
}
@media screen and (max-width:550px) {
    .service_s1_lower{
        grid-template-columns: repeat(1 , calc((100% / 1))) ;
    }
}
.service_s1_lower .service_s1_card{
    max-width: 383px;
    position: relative;
    transform: translateY(-255px);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgb(235, 235, 235);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.171)  ;

}
.service_s1_lower .service_s1_card .service_s1_img{
    height: 255px;
    overflow: hidden;
    position: relative;
}
.service_s1_lower .service_s1_card .service_s1_img .img{
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
}
.service_s1_lower .service_s1_card .service_s1_img:hover .img {
    scale: 1.09;
    
}
.service_s1_lower .service_s1_card:first-child .service_s1_img .img{
    background-image: url('../../assets/HomePage/Service/cs2_card_image.png');
}
.service_s1_lower .service_s1_card:nth-child(2) .service_s1_img .img{
    background-image:url('../../assets/HomePage/Service/gtav_card_image.jpg');
    
}
.service_s1_lower .service_s1_card:nth-child(3) .service_s1_img .img{
    background-image: url('../../assets/HomePage/Service/valorant_card_image.jpg');
    
}
.service_s1_lower .service_s1_card .service_s1_img::before{
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    z-index: 10;
    background-color: #10082986 ;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: all 0.5s ease;
    border-bottom: 5px solid var(--primary-color);
}
.service_s1_lower .service_s1_card:hover .service_s1_img:before{
    width: 100%;
}
.service_s1_logo{
    width: 120px;
    height: 120px;
    background-color: rgb(255, 251, 251);
    border-radius: 50%;
    margin: auto;
    transform: translateY(-50%);
    margin-bottom: -20px;
    position: relative;
    z-index: 15;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.171)  ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.service_s1_logo::before{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 50%;
    z-index: -1;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: all .5s cubic-bezier(.62,.21,.45,1.52);
    background: var(--theme-color1);
    z-index: -13;
}
.service_s1_logo svg{
    fill: var(--primary-color);
    font-size: 2.7rem;
}
.service_s1_lower .service_s1_card:hover svg{
    fill: white;
}
.service_s1_lower .service_s1_card:hover .service_s1_logo::before{
    -webkit-transform: scale(0.90);
    -moz-transform: scale(0.90);
    -ms-transform: scale(0.90);
}

.service_s1_lower .service_s1_card .text_cover{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
}
.service_s1_lower .service_s1_card .text_cover h2{
    font-family: var(--heading-font-family);
    font-size: 1.75rem;
    font-weight: bolder;
}
.service_s1_lower .service_s1_card .text_cover p{
    font-size: 1.2rem;
    color: #6a6a6a;
}