.verification_page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 100%;
    gap: 1rem;
}

.verification_page .loader{
    width: 50px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top: 5px solid rgb(39, 236, 32);
    animation: verification_spin 2s linear infinite;

}

@keyframes verification_spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }

}

.verification_page .tick{
    width: 70px;
    height: 50px;
    position: relative;
    flex-shrink: 0;
}

.verification_page .tick::before{
    content: "";
    position: absolute;
    bottom: 25px;
    left: 6px;
    width: 30px;
    height: 3px;
    background-color: rgb(39, 236, 32);
    transform-origin: left;
    transform:  rotateZ(45deg) ;
    animation: tick_grow 1s linear ;
}
.verification_page .tick::after{
    content: "";
    position: absolute;
    bottom: 4px;
    right: 45px;
    width: 50px;
    height: 3px;
    background-color: rgb(39, 236, 32);
    transform:  rotateZ(135deg) ;
    transform-origin: right;
    animation: tick_grow2 2s linear ;

}

@keyframes tick_grow {
    0%{
        width: 0px;
    }
    100%{
        width: 30px;
    }
}
@keyframes tick_grow2 {
    0%{
        width: 0px;
    }
    50%{
        width: 0px;
    }
    100%{
        width: 50px;
    }
}

